<div class="page-title-area page-title-department">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Especialidades</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Especialidades</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/001.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Pediatría</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" src="assets/img/iconos/001.png" >
                        </div>
                        <h3>Pediatría</h3>
                        <p>• Control del niño sano. • Recepción y atención al recién nacido. • Enfermedades de lactantes,
                            pres-escolar, escolar y adolescente.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="pediatria"> Leer mas.</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/002.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Audiometria</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>  
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/002.png" />
                        </div>
                        <h3>Audiometria</h3>
                        <p>• Diagnóstico, evaluación y prevención en niños y adultos. • Terapias de Audiología. 
                           • Tratamiento de patologías del habla, lenguaje, voz, y
                            audición.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="pediatria"> Leer mas.</a>
                    </div>  
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/003.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Cardiología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>  
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/003.png" />
                        </div>
                        <h3>Cardiología</h3>
                        <p>• Prueba de Esfuerzo,Ergometría. • Monitoreo ambulatorio de
                        presión arterial M.A.P.A. Holter de 24 y 48 horas. • Electrocardiograma
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="cardiologia"> Leer mas.</a>
                    </div>  
                </div>
            </div>            
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/004.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Dermatologia</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/004.png" />
                        </div>
                        <h3>Dermatologia</h3>
                        <p>• Control médico Integral. • Curaciones avanzadas.
                            • Procedimientos de cirugía menor.
                            • Internación de exámenes.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="dermatologia"> Leer mas.</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/005.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Endocrinologia</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/005.png" />
                        </div>
                        <h3>Endocrinologia</h3>
                        <p>• Diabetes y sus diferentes síntomas.
                            • Trastornos de paratiroides. • Alteraciones de crecimiento
                            y desarrollo.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="pediatria"> Leer mas.</a>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/006.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Ginecología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/006.png" />
                        </div>
                        <h3>Ginecología</h3>
                        <p>• Consultas ginecológicas y obstétricas de alto riesgo.
                            • Prevención del cáncer y cuello uterino. • Planificación familiar.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="ginecologia"> Leer mas.</a>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/007.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Medicina General</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/007.png" />
                        </div>
                        <h3>Medicina General</h3>
                        <p>• Control médico Integral. • Curaciones avanzadas.
                           • Procedimientos de cirugía menor. • Suturas.
                           • Ecografías generales.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="pediatria"> Leer mas.</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/008.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Medicina Interna</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/008.png" />
                        </div>
                        <h3>Medicina Interna</h3>
                        <p>• Control de enfermedades respiratorias.
                            • Control de enfermedades cardiovasculares.
                            • Endocrinas. • Digestivas.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="medicinainterna"> Leer mas.</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/009.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Medicina General / Cirugía</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/009.png" />
                        </div>
                        <h3>Medicina General / Cirugía</h3>
                        <p>• Control médico Integral. • Curaciones avanzadas.
                            • Procedimientos de cirugía menor.
                            • Cirugías mayores. • Suturas.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="pediatria"> Leer mas.</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/010.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Nutrición Clinica</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/010.png" />
                        </div>
                        <h3>Nutrición Clinica</h3>
                        <p>• Acompañamiento nutricional en cirugía pre y post bariátrica.
                           • Aumento de masa muscular. • Educación nutricional y  hábitos saludables.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="nutricionclinica"> Leer mas.</a>
                    </div>

                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/011.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Obstetricia</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/011.png" />
                        </div>
                        <h3>Obstetricia</h3>
                        <p>• Diagnóstico y control de embarazo.
                            • Control prenatal y postparto. • Realización de
                            Papanicolau y autoexamen de mamas.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="obstetricia"> Leer mas.</a>
                    </div>

                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/012.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Oftamología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/012.png" />
                        </div>
                        <h3>Oftamología</h3>
                        <p>• Tratamiento en enfermedades oculares.
                            • Exámenes oftalmológicos. • Cirugías Oftalmológicas
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="oftamologia"> Leer mas.</a>
                    </div>

                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/013.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Gastroenteorología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/013.png" />
                        </div>
                        <h3>Gastroenteorología</h3>
                        <p>• Diagnóstico y tratamiento de enfermedades gastrointestinales.
                           • Gastritis. • Ulcera gástrica y duodenal.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="gastroenteorologia"> Leer mas.</a>
                    </div>

                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/014.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Hematología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/014.png" />
                        </div>
                        <h3>Hematología</h3>
                        <p>• Diagnóstico y prevención oportuna de la sangre. 
                           • Alteraciones de glóbulos rojos. • Alteraciones en plaquetas
                           trombocitopenias
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="hematologia"> Leer mas.</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/015.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Alergología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/015.png" />
                        </div>
                        <h3>Alergología</h3>
                        <p>• Alergias cutáneas. • Dermatitis atópica.
                            • Alergias respiratorias (rinitis, asma). • Fortalecimiento del sistema
                            inmune.
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="alergologia"> Leer mas.</a>
                    </div>

                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/016.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Otorrinolaringologia</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/016.png" />
                        </div>
                        <h3>Otorrinolaringologia</h3>
                        <p>• Prueba de Esfuerzo,Ergometría. • Monitoreo ambulatorio de
                        presión arterial M.A.P.A. • Holter de 24 y 48 horas. • Electrocardiograma
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="pediatria"> Leer mas.</a>
                    </div>

                </div>
            </div>            
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/017.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Neurología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/017.png" />
                        </div>
                        <h3>Neurología</h3>
                        <p>• Prueba de Esfuerzo,Ergometría. • Monitoreo ambulatorio de
                        presión arterial M.A.P.A. • Holter de 24 y 48 horas. • Electrocardiograma
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="pediatria"> Leer mas.</a>
                    </div>

                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/018.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Psicología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/018.png" />
                        </div>
                        <h3>Psicología</h3>
                        <p>• Psicodiagnóstico. • Terapias.
                            • Evaluación de orientación vocacional.
                            • Técnicas cognitivas. • Informes psicológicos. 
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="psicologia"> Leer mas.</a>
                    </div>

                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/019.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Traumatología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/019.png" />
                        </div>
                        <h3>Traumatología</h3>
                        <p>• Tratamiento de fracturas, desguinces y desgarros musculares. 
                           • Tratamientos de Lesiones deportivas. • Artroscopias. • Prótesis. 
                        </p>
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="traumatologia"> Leer mas.</a>
                    </div>
                    
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <div class="department-front">
                        <img width="70" height="70" alt="" decoding="async" src="assets/img/iconos/020.png" class="attachment-full size-full" />
                        <ul></ul>
                        <ul></ul>
                        <h3>Urología</h3>
                        <a></a>
                        <ul></ul>
                        <ul></ul>
                    </div>
                    <div class="department-end">
                        <div class="cuadrante">
                            <img width="270" height="120" alt="" src="assets/img/iconos/020.png" />
                        </div>
                        <h3>Urología</h3>
                        <p> • Cirugía de próstata abierta y
                            endoscópica.  • Infección de transmisión sexual.
                             • Enfermedades de la próstata, vejiga y riñón. 
                        </p> 
                        <ul></ul>
                        <ul></ul>
                        <a routerLink="/doctor-details" fragment="pediatria"> Leer mas.</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>