<div class="page-title-area page-title-nosotros">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Nosotros</h2>
            <!--ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Especialidades</li>
                </ul-->
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-one/home-bg4.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h2>Quienes Somos</h2>
                    <h4>CEMAS S.A.</h4>
                    <p>Somos el primer Centro de
                        Especialidades Médicas ubicado
                        en el Cantón Santa Elena.
                        Nuestro compromiso es seguir
                        nuestra vocación para cuidar y
                        a su vez precautelar la salud de
                        nuestros pacientes.</p>

                   
                    <!--a routerLink="/about">Know More</a-->
                </div>
            </div>
        </div>
    </div>
</div>


<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="speciality-item">
                        <div class="row m-0">
                                <div class="department-item" width="64" height="64" alt="" decoding="async" >
                                    <!--i class="icofont-check-circled"></i-->
                                    <i class="icofont-bullseye" ></i>
                                    <h3>Misión</h3>
                                    <p>El Centro de Especialidades Médicas CEMAS S.A. esta dedicada a brindar atención médica integral de
                                        excelencia, con un equipo altamente competente
                                        comprometido con la capacitación y sastifacion de los
                                        estándares de seguridad, para así lograr una atención
                                        ética y profesional hacia los pacientes.</p>
                                </div>
                                
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="department-item" width="64" height="64" alt="" decoding="async" >
                    <!--i class="icofont-tooth"></i-->
                    <i class="icofont-eye-alt"></i>
                    <h3>Visión</h3>
                    <p>El Centro de Especialidades Médicas CEMAS S.A.
                        visionaría en ser una institución líder en la provincia
                        de Santa Elena, para así sostener un sistema integral
                        de salud privada, que ofrezca un espacio de
                        crecimiento profesional enfocado en la atención de
                        excelencia al paciente.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Valores</h2>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <img width="100" height="100" alt="" decoding="async" src="assets/img/nosotros/iconos/responsabilidad.png" class="attachment-full size-full" />
                    <h3></h3>
                    <h3></h3>
                    <h3>Responsabilidad</h3>
                    <p>           </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <img width="100" height="100" alt="" decoding="async" src="assets/img/nosotros/iconos/calidad.png" class="attachment-full size-full" />
                    <h3></h3>
                    <h3>Calidad y Calidez</h3>
                    <p>           </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <img width="100" height="100" alt="" decoding="async" src="assets/img/nosotros/iconos/respeto.png" class="attachment-full size-full" />
                    <h3></h3>
                    <h3>Respeto</h3>
                    <p>           </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <img width="100" height="100" alt="" decoding="async" src="assets/img/nosotros/iconos/compromiso.png" class="attachment-full size-full" />
                    <h3></h3>
                    <h3>Compromiso</h3>
                    <p>           </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <img width="100" height="100" alt="" decoding="async" src="assets/img/nosotros/iconos/orientacion.png" class="attachment-full size-full" />
                    <h3></h3>
                    <h3>Orientacion al Paciente</h3>
                    <p>           </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <img width="100" height="100" alt="" decoding="async" src="assets/img/nosotros/iconos/honestidad.png" class="attachment-full size-full" />
                    <h3></h3>
                    <h3>Honestidad y Etica Profesional</h3>
                    <p>           </p>
                </div>
            </div>



        </div>
    </div>
</section>


