<footer class="pb-70">
    <!--div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <h2>Join Our Newsletter</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <div class="newsletter-form">
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                                <button class="btn newsletter-btn" type="submit">Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div-->

    <div class="container ">
        <div class="row " >
            <div class="col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <a routerLink="/" class="logo"><img src="assets/img/logo_footer.png" alt="Logo"></a>
                        <ul>
                            <li>
                                <a> 9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</a>
                            </li>
                        </ul>
                    </div>
                        <div class="row">
                            <div class="footer-network col">
                                <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            </div>
                            <div class="footer-network col">
                                <a href="https://www.instagram.com/cemas_ec/" target="_blank">
                                    <i class="icofont-instagram"></i></a>
                            </div>
                            <div class="footer-network col">
                                <a href="#" target="_blank">
                                    <i class="fab fa-youtube"></i>
                                </a>
                            </div>
                       
                        </div>
                    </div>
            </div>
            
            <div class="col-6 col-lg-2 offset-lg-1 mb-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Contacto</h3>
                        <ul>
                            <li>
                                <a href="mailto:info@.cemas.ec">info&#64;cemas.ec</a>
                            </li>
                            <li>
                                <a href="tel:+593993112438"> +593 993112438</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-6 col-lg-2 mb-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Accesos</h3>
                        <ul>
                            <li><a routerLink="/nosotros">Nosotros</a></li>
                            <li><a routerLink="/departments">Especialidades</a></li>
                            <li><a routerLink="/doctor">Doctors</a></li>
                            <li><a routerLink="/contact">Contactos</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-6 col-lg-2 mb-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Nuestros Servicios</h3>
                        <ul>
                            <li><a routerLink="/">Medicina General</a></li>
                            <li><a routerLink="/">Pediatría</a></li>
                            <li><a routerLink="/">Medicina Ocupacional</a></li>
                            <li><a routerLink="/">Ginecología</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class=" col-6 col-lg-2 mb-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Horario</h3>
                        <ul>
                            <li><a>Lunes - Viernes: 07:00 - 19:00</a></li>
                            <li><a>Sabado: 07:00 - 17:00</a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
      </div>


    <!--div class="container">
        <div class="row">
            <div class="col-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h3>Contactanos</h3>
                        <ul>
                            <li>
                                <i class="icofont-ui-message"></i>
                                <a href="mailto:info@.cemas.ec">info&#64;cemas.ec</a>
                            </li>
                            <li>
                                <i class="icofont-stock-mobile"></i>
                                <a href="tel:+593993112438"> +593 993112438</a>
                                <-- <a href="tel:+236256256365">Call: +236 256 256 365</a>  >
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>
                                9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Accesos</h3>
                        <ul>
                            <li><a routerLink="/nosotros">Nosotros</a></li>
                            <li><a routerLink="/departments">Especialidades</a></li>
                            <li><a routerLink="/faq">Noticias</a></li>
                            <li><a routerLink="/doctor">Doctors</a></li>
                            <li><a routerLink="/contact">Contactanos</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Nuestros Servicios</h3>
                        <ul>
                            <li><a routerLink="/">Farmacia</a></li>
                            <li><a routerLink="/">Medicina General</a></li>
                            <li><a routerLink="/">Pediatría</a></li>
                            <li><a routerLink="/">Medicina Ocupacional</a></li>
                            <li><a routerLink="/">Obstetricia</a></li>
                            <li><a routerLink="/">Ginecología</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Accesos</h3>
                        <ul>
                            <li><a routerLink="/nosotros">Nosotros</a></li>
                            <li><a routerLink="/departments">Especialidades</a></li>
                            <li><a routerLink="/faq">Noticias</a></li>
                            <li><a routerLink="/doctor">Doctors</a></li>
                            <li><a routerLink="/contact">Contactanos</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Accesos</h3>
                        <ul>
                            <li><a routerLink="/nosotros">Nosotros</a></li>
                            <li><a routerLink="/departments">Especialidades</a></li>
                            <li><a routerLink="/faq">Noticias</a></li>
                            <li><a routerLink="/doctor">Doctors</a></li>
                            <li><a routerLink="/contact">Contactanos</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div-->
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>Designed by Aebsa Solution | © 2023 All Rights Reserved <a href="https://aebsa.net/" target="_blank">Aebsa</a></p>
        </div>
    </div>
</div>