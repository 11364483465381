<div class="page-title-area page-title-services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Servicios</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Servicios</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/consulta-medica.png" class="attachment-full size-full" />                        
                        <h3>Consultas Médicas</h3>
                        <p>   </p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Consultas Médicas</h3>
                        <p> </p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/laboratorio.png" class="attachment-full size-full" />                        
                        <h3>Laboratorio</h3>
                        <p> </p>
                    </div>

                    <div class="service-end">
                        <h3>Laboratorio</h3>
                        <i class="icofont-microscope"></i>
                        <p></p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/farmacia.png" class="attachment-full size-full" />                        
                        <h3>Farmacia</h3>
                        <p></p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-pills"></i>
                        <h3>Farmacia</h3>
                        <p></p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/ecografia.png" class="attachment-full size-full" />                                                <h3>Ecografía</h3>
                        <p></p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heartbeat"></i>
                        <h3>Ecografía</h3>
                        <p></p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/rayos-x.png" class="attachment-full size-full" />                        
                        <h3>Imagenes</h3>
                        <p></p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-xray"></i>
                        <p></p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/salud-ocupacional.png" class="attachment-full size-full" />                        
                        <h3>Salud Ocupacional</h3>
                        <p></p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-worker"></i>
                        <h3>Salud Ocupacional</h3>
                        <p></p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/blood-analysis.png" class="attachment-full size-full" />                        
                        <h3>Toma de Muestras</h3>
                        <p></p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-worker"></i>
                        <h3>Toma de muestras</h3>
                        <p></p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/especialidades.png" class="attachment-full size-full" />                        
                        <h3>Especialidades</h3>
                        <p></p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-worker"></i>
                        <h3>Especialidades</h3>
                        <p></p>
                        <a routerLink="/departments">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <img width="64" height="64" alt="" decoding="async" src="assets/img/iconos/promociones.png" class="attachment-full size-full" />                        
                        <h3>Promociones</h3>
                        <p></p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-worker"></i>
                        <h3>Promociones</h3>
                        <p></p>
                        <a routerLink="/departments">Read More</a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>

<!--section class="expertise-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expertise</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-doctor-alt"></i>
                                    <h3>Certified Doctors</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-stretcher"></i>
                                    <h3>Emergency</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-network"></i>
                                    <h3>Teachnology</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-ambulance-cross"></i>
                                    <h3>Ambulance</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="expertise-right">
                        <img src="assets/img/home-one/home-bg6.jpg" alt="Expertise">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Ultimas noticias</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Cemas, Festeja a los pequeños de la casa</a></h3>
                        <p>..</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>